
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.snowflake {
  animation: 2s linear spin infinite;

  position: fixed;
  top: 0;
  left: 0;
  font-size: 16px;
  line-height: 0;
  pointer-events: none;
  user-select: none;
  opacity: 1;
  z-index: 200000;

  transition: top 0.1s ease-in-out, left 0.5s ease-in-out;
}

.tangerine-counter {
  color: white;
  position: fixed;
  bottom: 100px;
  right: 25px;
  background: orange;
  border-radius: 100%;
  line-height: 0;
  height: 64px;
  width: 64px;
  font-size: 16px;
  z-index: 100000;
  display: flex;
  align-items: center;
  user-select: none;
  justify-content: center;
}

.deer-left {
  align-self: center;
  opacity: 0.5;
  user-select: none;
}

.deer-list {
  color: white;
  position: fixed;
  bottom: 100px;
  right: 25px;
  background: brown;
  border-radius: 32px;
  font-size: 16px;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: end;
  justify-content: center;
  user-select: none;
}
