$small: 767px;
$large: 1200px;

@mixin mq($media) {
  @if $media==mobile {
    @media only screen and (max-width: $small) {
      @content;
    }
  } @else if $media==mobile-tablet {
    @media only screen and (max-width: ($large - 1)) {
      @content;
    }
  } @else if $media==tablet {
    @media only screen and (min-width: ($small + 1)) {
      @content;
    }
  } @else if $media==tablet-only {
    @media only screen and (min-width: ($small + 1)) and (max-width: ($large - 1)) {
      @content;
    }
  } @else if $media==desktop {
    @media only screen and (min-width: $large) {
      @content;
    }
  }
}

@function stripUnit($value) {
  @return $value / ($value * 0 + 1);
}

@function rem($pxValue) {
  @return #{stripUnit($pxValue) / stripUnit(16)}rem;
}
