
$height-chat-input: 80px;


.chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  // overflow-y: auto;
  background: #fff;
  padding-bottom: $height-chat-input;
}

.response {
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  min-height: 72px;
  min-width: 243px;
  max-width: 600px;
  background: rgba(196, 211, 234, 0.5);
  border-radius: 8px;
  margin: 8px 32px;
  padding: 12px;
  word-break: break-all;

  &__person {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #515c72;
    margin: 0 0 0 6px;
  }

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    align-self: flex-start;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6a7b95;
    align-self: flex-end;
  }

  &__container {
    display: flex;
    align-items: center;
    margin: 0 0 4px 0;
  }
}

.request {
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  min-height: 52px;
  min-width: 243px;
  max-width: 600px;

  background: #e9ebf3;
  border-radius: 8px;
  padding: 12px;
  margin: 8px 32px;
  word-break: break-all;

  &__person {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #515c72;
    align-self: flex-start;
  }

  &__container {
    align-self: flex-end;
  }

  &__isread {
    width: 14px !important;
    height: 14px !important;
    margin: 0 4px 0 0;
  }

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    align-self: flex-start;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6a7b95;
  }
}

.icon-send {
  width: 24px !important;
  height: 24px !important;
}

.chat-input {
  width: 100%;

  &:deep(.base-input__icon) {
    display: flex;
    align-items: center;
  }
}

.input-container {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  background: #fff;
  margin: 30px;
  max-height: $height-chat-input;
}

.chip {
  width: 100px;
  white-space: nowrap;
  align-self: center;
  cursor: default;
  user-select: none;
  margin: 31px 0;
}

.admin-icon {
  width: 24px !important;
  height: 24px !important;
  fill: #22c951;
}
